
import { useAtom } from 'jotai';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import StudentLogin from './authentication/StudentLogin';
import Explore from './explore/Explore';
import Homepage from './homepage/Homepage';
import { providerLoggedIn, studentLoggedIn } from './state/AuthManager';
import ProviderPrivateRouter from './routers/ProviderPrivateRouter';
import ProviderHome from './provider/ProviderHome';
import ProviderLogin from './authentication/ProviderLogin';
import ProviderIntake from './provider/ProviderIntake';
import ProviderRegistration from './provider/ProviderRegistration';
import StudentPrivateRouter from './routers/StudentPrivateRouter';
import LandingPage from './homepage/LandingPage';
import ComingSoon from './homepage/ComingSoon';



export interface IApplicationProps {}

const App: React.FunctionComponent<IApplicationProps> = (props) => {

    const [isProviderLoggedIn] = useAtom(providerLoggedIn)
    const [isStudentLoggedIn] = useAtom(studentLoggedIn)

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ComingSoon />} />
                    
                {/* <Route element={<ProviderPrivateRouter isLoggedIn={isProviderLoggedIn} />}>
                    <Route path="provider-home" element={<ProviderHome />} />
                </Route>

                <Route element={<StudentPrivateRouter isLoggedIn={isStudentLoggedIn} />}>
                    <Route path="explore" element={<Explore />}/>
                </Route>
                    
                
                <Route path="student-login" element={<StudentLogin />} />
                <Route path="provider-login" element={<ProviderLogin />} />
                <Route path="provider-registration" element={<ProviderRegistration />} />
                <Route path="provider-details" element={<ProviderIntake />} />
                <Route path="landing-page" element={<LandingPage />} /> */}

            </Routes>
        </BrowserRouter>
    );
};

export default App