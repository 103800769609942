import { Box, Grid, Typography, ThemeProvider } from "@mui/material";
import { headfirstTheme } from '../utils/theme';

export default function ComingSoon() {

    return (
    <ThemeProvider theme={headfirstTheme}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            >
            
            <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
            >

                <Grid item>
                    <Box
                        component="img"
                        sx={{
                        height: 104,
                        }}
                        alt="Your logo."
                        src={"./l3.png"}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5"> 
                    Coming Soon...
                </Typography>
                </Grid>
                
           

            </Grid>
               
        </Box>
    </ThemeProvider>
    );
}