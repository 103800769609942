import { createTheme } from "@mui/material";
import { purple } from "@mui/material/colors";
const font =  "'Nunito', sans-serif";

export const headfirstTheme = createTheme({
    typography: {
        fontFamily: font,
        fontWeightRegular: 800,
        button: { 
            textTransform: "none" 
        }
    },
    palette: {
        primary: {
          main: '#7d005e',
        },
        secondary: {
          main: '#f9f2e4',
        },
      },
  });